import {BodyText, Dialog, DialogType, TailwindStandardWidth} from "@oculus/component-library";
import {exitApp, isElectronEnvironment} from "../helper";
import React, {useEffect, useState} from "react";

const MIN_SCREEN_WIDTH = 1279;
const MIN_SCREEN_HEIGHT = 700;

/**
 * `ScreenSizePopup` is component that checks the screen width of the device and alerts the user
 * if the resolution is not supported by the software.
 *
 * The alert is presented as a dialog with two possible options that depend on whether the window can be resized.
 * - In case the window can be resized, an option is provided to enlarge the window.
 * - If the resolution is too small and cannot be adjusted, the user is advised to exit the software.
 *
 * @component
 */
const ScreenSizePopup: React.FC = () => {
    const [canResize, setCanResize] = useState(false);
    const [screenWidth, setScreenWidth] = useState<number>();
    const [screenHeight, setScreenHeight] = useState<number>();

    useEffect(() => {
        const updateScreenDimensions = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };
        updateScreenDimensions();
        window.addEventListener("resize", updateScreenDimensions);
        return () => window.removeEventListener("resize", updateScreenDimensions);
    }, []);

    useEffect(() => {
        if (!isElectronEnvironment()) {
            return;
        }
        const updateCanResize = () => {
            const availableWidth = window.screen.availWidth - window.outerWidth;
            const availableHeight = window.screen.availHeight - window.outerHeight;
            const widthCanResize = MIN_SCREEN_WIDTH - window.innerWidth;
            const heightCanResize = MIN_SCREEN_HEIGHT - window.innerHeight;
            setCanResize(availableWidth >= widthCanResize && availableHeight >= heightCanResize);
        };
        updateCanResize();
        const intervalId = window.setInterval(updateCanResize, 1000);
        return () => window.clearInterval(intervalId);
    }, [screenWidth, screenHeight]);

    return (
        <Dialog headline="Auflösung wird nicht unterstützt" width={TailwindStandardWidth.W152}
                show={screenWidth !== undefined && screenHeight !== undefined && (screenWidth < MIN_SCREEN_WIDTH || screenHeight < MIN_SCREEN_HEIGHT)}
                type={canResize ? DialogType.Info : DialogType.Warning} showCloseButton={false}
                label={canResize ? "Hinweis" : "Fehlermeldung"}
                buttons={[
                    ...canResize ? [{
                        label: "Fenster vergrößern",
                        primary: true,
                        onClick: () => window.resizeBy(Math.max(MIN_SCREEN_WIDTH - window.innerWidth, 0), Math.max(MIN_SCREEN_HEIGHT - window.innerHeight, 0))
                    }] : [],
                    {label: "Software beenden", primary: !canResize, onClick: () => exitApp()},
                ]}>
            <BodyText furtherClasses="!m-0" text={canResize ? (
                "Die von Ihnen gewählte Auflösung wird von unserer Perimeter-Software nicht unterstützt. Für die optimale Bedienbarkeit und Nutzung empfehlen " +
                "wir Ihnen die Verwendung eines Endgeräts oder Displays mit einer höheren Auflösung. " +
                "Sofern möglich, können Sie die Anwendung manuell oder über den unten liegenden Button vergrößern, um wieder zu einer unterstützten " +
                "Auflösung zu gelangen. Sollte dies nicht möglich sein, müssen Sie die Anwendung schließen."
            ) : (
                "Das von Ihnen gewählte Endgerät oder Display verfügt nicht über die notwendige Auflösung, um unserer Software optimal nutzen zu können. " +
                "Bitte beenden Sie die Software."
            )}/>
        </Dialog>
    )
};

export default ScreenSizePopup;